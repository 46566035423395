<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Feather Icons</h2>
    </div>
    <!-- BEGIN: Icon List -->
    <div
      class="intro-y grid grid-cols-12 sm:gap-6 row-gap-6 box px-5 py-8 mt-5"
    >
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ActivityIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ActivityIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <AirplayIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">AirplayIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <AlertCircleIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">AlertCircleIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <AlertOctagonIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">AlertOctagonIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <AlertTriangleIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">AlertTriangleIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <AlignCenterIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">AlignCenterIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <AlignJustifyIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">AlignJustifyIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <AlignLeftIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">AlignLeftIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <AlignRightIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">AlignRightIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <AnchorIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">AnchorIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ApertureIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ApertureIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ArchiveIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ArchiveIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ArrowDownCircleIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ArrowDownCircleIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ArrowDownLeftIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ArrowDownLeftIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ArrowDownRightIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ArrowDownRightIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ArrowDownIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ArrowDownIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ArrowLeftCircleIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ArrowLeftCircleIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ArrowLeftIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ArrowLeftIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ArrowRightCircleIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ArrowRightCircleIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ArrowRightIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ArrowRightIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ArrowUpCircleIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ArrowUpCircleIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ArrowUpLeftIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ArrowUpLeftIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ArrowUpRightIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ArrowUpRightIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ArrowUpIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ArrowUpIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <AtSignIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">AtSignIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <AwardIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">AwardIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <BarChart2Icon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">BarChart2Icon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <BarChartIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">BarChartIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <BatteryChargingIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">BatteryChargingIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <BatteryIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">BatteryIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <BellOffIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">BellOffIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <BellIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">BellIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <BluetoothIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">BluetoothIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <BoldIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">BoldIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <BookOpenIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">BookOpenIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <BookIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">BookIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <BookmarkIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">BookmarkIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <BoxIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">BoxIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <BriefcaseIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">BriefcaseIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <CalendarIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">CalendarIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <CameraOffIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">CameraOffIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <CameraIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">CameraIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <CastIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">CastIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <CheckCircleIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">CheckCircleIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <CheckSquareIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">CheckSquareIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <CheckIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">CheckIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ChevronDownIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ChevronDownIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ChevronLeftIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ChevronLeftIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ChevronRightIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ChevronRightIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ChevronUpIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ChevronUpIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ChevronsDownIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ChevronsDownIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ChevronsLeftIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ChevronsLeftIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ChevronsRightIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ChevronsRightIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ChevronsUpIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ChevronsUpIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ChromeIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ChromeIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <CircleIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">CircleIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ClipboardIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ClipboardIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ClockIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ClockIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <CloudDrizzleIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">CloudDrizzleIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <CloudLightningIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">CloudLightningIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <CloudOffIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">CloudOffIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <CloudRainIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">CloudRainIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <CloudSnowIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">CloudSnowIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <CloudIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">CloudIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <CodeIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">CodeIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <CodepenIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">CodepenIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <CodesandboxIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">CodesandboxIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <CoffeeIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">CoffeeIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ColumnsIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ColumnsIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <CommandIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">CommandIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <CompassIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">CompassIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <CopyIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">CopyIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <CornerDownLeftIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">CornerDownLeftIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <CornerDownRightIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">CornerDownRightIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <CornerLeftDownIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">CornerLeftDownIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <CornerLeftUpIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">CornerLeftUpIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <CornerRightDownIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">CornerRightDownIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <CornerRightUpIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">CornerRightUpIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <CornerUpLeftIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">CornerUpLeftIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <CornerUpRightIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">CornerUpRightIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <CpuIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">CpuIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <CreditCardIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">CreditCardIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <CropIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">CropIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <CrosshairIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">CrosshairIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <DatabaseIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">DatabaseIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <DeleteIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">DeleteIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <DiscIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">DiscIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <DivideCircleIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">DivideCircleIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <DivideSquareIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">DivideSquareIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <DivideIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">DivideIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <DollarSignIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">DollarSignIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <DownloadCloudIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">DownloadCloudIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <DownloadIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">DownloadIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <DribbbleIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">DribbbleIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <DropletIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">DropletIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <Edit2Icon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">Edit2Icon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <Edit3Icon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">Edit3Icon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <EditIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">EditIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ExternalLinkIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ExternalLinkIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <EyeOffIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">EyeOffIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <EyeIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">EyeIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <FacebookIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">FacebookIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <FastForwardIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">FastForwardIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <FeatherIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">FeatherIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <FigmaIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">FigmaIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <FileMinusIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">FileMinusIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <FilePlusIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">FilePlusIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <FileTextIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">FileTextIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <FileIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">FileIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <FilmIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">FilmIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <FilterIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">FilterIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <FlagIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">FlagIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <FolderMinusIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">FolderMinusIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <FolderPlusIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">FolderPlusIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <FolderIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">FolderIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <FramerIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">FramerIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <FrownIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">FrownIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <GiftIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">GiftIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <GitBranchIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">GitBranchIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <GitCommitIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">GitCommitIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <GitMergeIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">GitMergeIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <GitPullRequestIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">GitPullRequestIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <GithubIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">GithubIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <GitlabIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">GitlabIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <GlobeIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">GlobeIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <GridIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">GridIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <HardDriveIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">HardDriveIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <HashIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">HashIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <HeadphonesIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">HeadphonesIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <HeartIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">HeartIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <HelpCircleIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">HelpCircleIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <HexagonIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">HexagonIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <HomeIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">HomeIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ImageIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ImageIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <InboxIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">InboxIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <InfoIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">InfoIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <InstagramIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">InstagramIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ItalicIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ItalicIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <KeyIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">KeyIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <LayersIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">LayersIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <LayoutIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">LayoutIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <LifeBuoyIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">LifeBuoyIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <Link2Icon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">Link2Icon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <LinkIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">LinkIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <LinkedinIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">LinkedinIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ListIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ListIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <LoaderIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">LoaderIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <LockIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">LockIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <LogInIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">LogInIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <LogOutIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">LogOutIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <MailIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">MailIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <MapPinIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">MapPinIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <MapIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">MapIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <Maximize2Icon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">Maximize2Icon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <MaximizeIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">MaximizeIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <MehIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">MehIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <MenuIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">MenuIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <MessageCircleIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">MessageCircleIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <MessageSquareIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">MessageSquareIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <MicOffIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">MicOffIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <MicIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">MicIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <Minimize2Icon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">Minimize2Icon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <MinimizeIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">MinimizeIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <MinusCircleIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">MinusCircleIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <MinusSquareIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">MinusSquareIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <MinusIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">MinusIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <MonitorIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">MonitorIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <MoonIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">MoonIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <MoreHorizontalIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">MoreHorizontalIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <MoreVerticalIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">MoreVerticalIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <MousePointerIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">MousePointerIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <MoveIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">MoveIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <MusicIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">MusicIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <Navigation2Icon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">Navigation2Icon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <NavigationIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">NavigationIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <OctagonIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">OctagonIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <PackageIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">PackageIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <PaperclipIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">PaperclipIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <PauseCircleIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">PauseCircleIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <PauseIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">PauseIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <PenToolIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">PenToolIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <PercentIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">PercentIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <PhoneCallIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">PhoneCallIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <PhoneForwardedIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">PhoneForwardedIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <PhoneIncomingIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">PhoneIncomingIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <PhoneMissedIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">PhoneMissedIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <PhoneOffIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">PhoneOffIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <PhoneOutgoingIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">PhoneOutgoingIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <PhoneIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">PhoneIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <PieChartIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">PieChartIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <PlayCircleIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">PlayCircleIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <PlayIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">PlayIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <PlusCircleIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">PlusCircleIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <PlusSquareIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">PlusSquareIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <PlusIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">PlusIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <PocketIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">PocketIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <PowerIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">PowerIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <PrinterIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">PrinterIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <RadioIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">RadioIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <RefreshCcwIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">RefreshCcwIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <RefreshCwIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">RefreshCwIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <RepeatIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">RepeatIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <RewindIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">RewindIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <RotateCcwIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">RotateCcwIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <RotateCwIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">RotateCwIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <RssIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">RssIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <SaveIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">SaveIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ScissorsIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ScissorsIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <SearchIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">SearchIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <SendIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">SendIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ServerIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ServerIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <SettingsIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">SettingsIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <Share2Icon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">Share2Icon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ShareIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ShareIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ShieldOffIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ShieldOffIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ShieldIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ShieldIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ShoppingBagIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ShoppingBagIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ShoppingCartIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ShoppingCartIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ShuffleIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ShuffleIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <SidebarIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">SidebarIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <SkipBackIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">SkipBackIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <SkipForwardIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">SkipForwardIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <SlackIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">SlackIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <SlashIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">SlashIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <SlidersIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">SlidersIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <SmartphoneIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">SmartphoneIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <SmileIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">SmileIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <SpeakerIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">SpeakerIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <SquareIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">SquareIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <StarIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">StarIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <StopCircleIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">StopCircleIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <SunIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">SunIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <SunriseIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">SunriseIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <SunsetIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">SunsetIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <TabletIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">TabletIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <TagIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">TagIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <TargetIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">TargetIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <TerminalIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">TerminalIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ThermometerIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ThermometerIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ThumbsDownIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ThumbsDownIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ThumbsUpIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ThumbsUpIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ToggleLeftIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ToggleLeftIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ToggleRightIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ToggleRightIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ToolIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ToolIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <Trash2Icon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">Trash2Icon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <TrashIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">TrashIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <TrelloIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">TrelloIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <TrendingDownIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">TrendingDownIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <TrendingUpIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">TrendingUpIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <TriangleIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">TriangleIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <TruckIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">TruckIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <TvIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">TvIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <TwitchIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">TwitchIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <TwitterIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">TwitterIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <TypeIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">TypeIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <UmbrellaIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">UmbrellaIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <UnderlineIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">UnderlineIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <UnlockIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">UnlockIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <UploadCloudIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">UploadCloudIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <UploadIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">UploadIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <UserCheckIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">UserCheckIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <UserMinusIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">UserMinusIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <UserPlusIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">UserPlusIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <UserXIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">UserXIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <UserIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">UserIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <UsersIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">UsersIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <VideoOffIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">VideoOffIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <VideoIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">VideoIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <VoicemailIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">VoicemailIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <Volume1Icon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">Volume1Icon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <Volume2Icon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">Volume2Icon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <VolumeXIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">VolumeXIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <VolumeIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">VolumeIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <WatchIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">WatchIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <WifiOffIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">WifiOffIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <WifiIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">WifiIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <WindIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">WindIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <XCircleIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">XCircleIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <XOctagonIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">XOctagonIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <XSquareIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">XSquareIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <XIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">XIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <YoutubeIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">YoutubeIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ZapOffIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ZapOffIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ZapIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ZapIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ZoomInIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ZoomInIcon</div>
      </div>
      <div class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2">
        <ZoomOutIcon class="mx-auto" />
        <div class="text-center text-xs mt-2 icon" @click="onClick">ZoomOutIcon</div>
      </div>
    </div>
    <!-- END: Icon List -->
  </div>
</template>

<script>
export default {
  name: "icon",
  methods: {
    onClick(val) {
      const name = val.target.childNodes[0].data
      console.log(name)
      const that = this
      this.$copyText(name).then(function (e) {
        console.log(e)
        that.$message.success("图标名复制成功");
      }, function (e) {
        console.log(e)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.icon {
  cursor: pointer;
}
</style>
